import instance from 'utils/instance';
import { changePasswordApi, profileDataApi, logoutApi } from 'utils/api';
import * as sha256 from 'sha256';

const changePassword = async ({ oldPassword, password }) => {
  const body = {
    oldPassword: sha256(oldPassword),
    password: sha256(password)
  };

  const data = await instance.put(changePasswordApi, body);

  return data;
};

const getUserData = async () => {
  const data = await instance.get(profileDataApi);

  return data;
};

const updateProfile = async ({ firstName, lastName }) => {
  const body = { firstName, lastName };

  const data = await instance.put(profileDataApi, body);

  return data;
};

const logout = async () => {
  const data = await instance.put(logoutApi);
  return data;
};

export { changePassword, getUserData, logout, updateProfile };
