import moment from 'moment';
import {
  CLEAR_GET_CMS_INFO,
  GET_CMS_INFO,
  GET_CMS_INFO_LOADING,
  GET_CMS_INFO_ERROR
} from 'configStore/constants/cmsDataConstants';
import { CLEAR_AUTHENTICATION, SESSION_EXPIRED } from 'configStore/constants/authUserConstants';

// import addGame from 'utils/dummyData/sectionData/addGame.json';
// import addCategory from 'utils/dummyData/sectionData/addCategory.json';
// import editCategory from 'utils/dummyData/editCategory.json';
// import addBanner from 'utils/dummyData/addBanner.json';
// import editBanner from 'utils/dummyData/editBanner.json';
// import allGames from 'utils/dummyData/sectionData/allGames.json';
// import banners from 'utils/dummyData/banners.json';
// import categories from 'utils/dummyData/sectionData/categories.json';
// import termsAndConditions from 'utils/dummyData/sectionData/termsAndConditions.json';
// import tags from 'utils/dummyData/sectionData/tags.json';
// import addTag from 'utils/dummyData/sectionData/addTag.json';
// import editTag from 'utils/dummyData/sectionData/editTag.json';
// import videos from 'utils/dummyData/sectionData/videos.json';
// import editVideo from 'utils/dummyData/sectionData/editVideo.json';
// import requests from 'utils/dummyData/sectionData/requests.json';
// import appUsers from 'utils/dummyData/sectionData/appUsers.json';
// import ads from 'utils/dummyData/sectionData/ads.json';
// import addads from 'utils/dummyData/sectionData/addAds.json';
// import editads from 'utils/dummyData/sectionData/editAds.json';
// import allstickers from 'utils/dummyData/sectionData/stickers.json';
// import addstickers from 'utils/dummyData/sectionData/addStickers.json';

export const initialState = {
  loadedAt: null,
  loading: null,
  error: null,
  result: null,
  navLinks: null
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CMS_INFO_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
        loadedAt: null,
        navLinks: null
      };
    }
    case GET_CMS_INFO: {
      const navLinks = action.payload;
      // navLinks.common = [];
      // navLinks.common.push(allstickers);
      // navLinks.common.push(addstickers);
      // navLinks.common.push(ads);
      // navLinks.common.push(addads);
      // navLinks.common.push(editads);

      // navLinks.common.push(addGame);
      // navLinks.common.push(addCategory);
      // navLinks.common.push(editCategory);
      // navLinks.common.push(addBanner);
      // navLinks.common.push(editBanner);
      // navLinks.common.push(categories);
      // navLinks.common.push(banners);
      // navLinks.common.push(allGames);
      // navLinks.common.push(termsAndConditions);
      // navLinks.common.push(tags);
      // navLinks.common.push(addTag);
      // navLinks.common.push(editTag);
      // navLinks.common.push(videos);
      // navLinks.common.push(editVideo);

      const result = Object.values(navLinks).flat();

      return {
        ...state,
        loadedAt: moment(),
        loading: false,
        result,
        navLinks
      };
    }
    case GET_CMS_INFO_ERROR: {
      return {
        ...state,
        loadedAt: null,
        loading: false,
        error: action.error,
        result: null
      };
    }
    case CLEAR_GET_CMS_INFO:
    case CLEAR_AUTHENTICATION:
    case SESSION_EXPIRED: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export default cmsReducer;
