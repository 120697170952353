import moment from 'moment';
import {
  CLEAR_GET_LOV,
  GET_LOV_LOADING,
  GET_LOV,
  GET_LOV_ERROR
} from 'configStore/constants/lovConstants';
import { CLEAR_AUTHENTICATION, SESSION_EXPIRED } from '../constants/authUserConstants';

export const initialState = {
  loadedAt: null,
  loading: null,
  error: null,
  result: null
};

const lovReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOV_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
        loadedAt: null
      };
    }
    case GET_LOV: {
      return {
        ...state,
        loadedAt: moment(),
        loading: false,
        result: action.payload
      };
    }

    case GET_LOV_ERROR: {
      return {
        ...state,
        loadedAt: null,
        loading: false,
        error: action.error,
        result: null
      };
    }
    case CLEAR_GET_LOV: {
      return {
        ...initialState
      };
    }
    case CLEAR_AUTHENTICATION:
    case SESSION_EXPIRED:
      return { ...initialState };
    default:
      return state;
  }
};

export default lovReducer;
