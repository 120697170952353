const cmsConfig = {
  fallbackFontSize: 16,
  fontSizes: {
    en: 16,
    ar: 17
  },
  languageSwitcher: {
    show: false,
    languages: ['en', 'ar']
  },
  authentication: {
    hideCmsBackOfficeTitle: false,
    useOtp: false,
    otpMaxLength: 8,
    centerLayout: false,
    showIllustration: false
  },
  dynamicIcons: {
    paths: {
      navigation: {
        links: (icon) => `components/icons/navigationIcons/${icon}.js`
      },
      form: {
        save: (icon) => `components/icons/formIcons/${icon}.js`
      }
    }
  },
  table: {
    resizableColumns: true,
    reorderableColumns: false,
    limits: [10, 15, 20, 25, 50],
    export: {
      fileName: 'Data'
    }
  },
  charts: {
    defaultColors: ['#0000FF', '#0066FF', '#00CCFF']
  },
  colorPicker: {
    defaultColor: '#555555',
    defaultColorType: 'hex',
    roundPicker: false
  },
  maps: {
    showCopyModal: true,
    isSearchable: true,
    isLocalized: false,
    defaultLanguage: 'en',
    defaultCenter: {
      lat: 33,
      lng: 35
    }
  },
  header: {
    showBgImage: false,
    showNameEmail: false
  },
  navigation: {
    groupLinks: false
  },
  footer: {
    show: false,
    showBgImage: false
  },
  phoneInput: {
    defaultCountry: 'lb'
  },
  videoPlayer: {
    file: {
      attributes: { controlsList: 'nodownload', disablepictureinpicture: 'true' }
    }
  },
  image: {
    dokaUtils: ['crop', 'filter', 'color', 'markup'],
    aspectRatios: {
      landscape: 9 / 16,
      portrait: 3 / 2,
      square: 1 / 1
    },
    acceptTypes: ['jpeg', 'gif', 'jpg', 'png', 'svg'],
    // sizes in Bytes used to determine maximum image size in uploadVideoThumbnail and Doka Modal
    size: 2000000,
    editorSize: 500000
  },
  video: {
    // Size in bits
    size: 2000000
  },
  errorMessages: {
    minLength: 'validationError.minLength',
    required: 'required',
    maxLength: 'validationError.maxLength',
    lengthRange: 'validationError.lengthRange',
    numberRequired: 'validationError.numberRequired',
    integerRequired: 'validationError.integerRequired',
    decimalRequired: 'validationError.decimalRequired',
    minValue: 'validationError.minValue',
    maxValue: 'validationError.maxValue',
    valueRange: 'validationError.valueRange',
    dateRequired: 'validationError.dateRequired',
    rangeDateRequired: 'validationError.rangeDateRequired',
    differentError: 'validationError.differentError',
    atLeastOneError: 'validationError.atLeastOneError'
  },
  multiSelectConfig: {
    selectedAllMessage: 'selectedAllMessage',
    selectAllOption: {
      label: 'selectAllOption',
      value: '*'
    }
  }
};

export default cmsConfig;
