import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Approve from 'components/icons/Approve';
import Close from 'components/icons/Close';

import './CustomAlert.scss';

const CustomAlert = ({ message, type }) => {
  const { t } = useTranslation();

  return (
    <div className="action">
      {type === 'success' ? <Approve /> : <Close />}
      <span>{t(`alerts.${message}`, message)}</span>
    </div>
  );
};

CustomAlert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'danger']).isRequired
};

export default CustomAlert;
