import React from 'react';
import PropTypes from 'prop-types';
import './CustomButton.scss';
import Button from 'react-bootstrap/Button';

const CustomButton = React.forwardRef(
  (
    { label, onClick, leftIconSource, rightIconSource, variant, disabled, type, className },
    ref
  ) => {
    const hasLeftIcon = leftIconSource !== '';
    const hasRightIcon = rightIconSource !== '';

    const centerText = !(hasLeftIcon || hasRightIcon) ? 'justify-content-center w-100' : '';

    return (
      <Button
        ref={ref}
        variant={variant}
        className={`button-font d-flex ${centerText} align-items-center ${type} ${className}`}
        disabled={disabled}
        onClick={onClick}>
        {hasLeftIcon && <img src={leftIconSource} alt="leftIcon" className="leftIcon" />}
        {label}
        {hasRightIcon && <img src={rightIconSource} alt="rightIcon" className="rightIcon" />}
      </Button>
    );
  }
);

CustomButton.displayName = 'CustomButton';

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  leftIconSource: PropTypes.string,
  rightIconSource: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['', 'create', 'cancel'])
};

CustomButton.defaultProps = {
  leftIconSource: '',
  rightIconSource: '',
  variant: 'outline-primary',
  className: '',
  type: '',
  disabled: false
};

export default CustomButton;
