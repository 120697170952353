import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import config from 'utils/config';
import appReducer from './reducers';

const middlewares = [thunk];
let s;

if (config.isLocalEnv || config.isDevEnv) {
  middlewares.push(logger);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  s = composeEnhancers(applyMiddleware(...middlewares))(createStore)(appReducer);
} else {
  s = compose(applyMiddleware(...middlewares))(createStore)(appReducer);
}

const store = s;
const persistor = persistStore(store);

export { persistor, store };
