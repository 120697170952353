import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CustomButton from 'components/atoms/customButton/CustomButton';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.onRetryHandler = this.onRetryHandler.bind(this);
  }

  // componentDidCatch(error, errorInfo) {
  //   // only to log the error
  // }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  onRetryHandler() {
    const { history } = this.props;
    history.go(0);
  }

  render() {
    const { hasError } = this.state;
    const { showReloadPage, children, t } = this.props;

    if (hasError) {
      return (
        <div className={styles.errorBoundary}>
          <h6>{t('alerts.somethingWentWrong')}</h6>
          {showReloadPage && (
            <CustomButton
              label={t('reload')}
              onClick={this.onRetryHandler}
              variant="primary"
              className="w-auto mx-auto"
            />
          )}
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  showReloadPage: false
};

ErrorBoundary.propTypes = {
  showReloadPage: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired
};

export default withTranslation()(withRouter(ErrorBoundary));
