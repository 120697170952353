import {
  clearAuthentication,
  saveAuthentication,
  sessionExpired
} from 'configStore/actions/authUserActions';
import { showErrorNotification } from 'utils/helpers';
import { refreshTokenApi, logoutApi } from 'utils/api';
import { serializeToFormUrlEncoded } from 'utils/common';

const requestSuccessInterceptor = (store, req) => {
  const { authUser } = store.getState();

  req.headers.authorization = authUser.access_token;

  if (req?.url === refreshTokenApi) {
    req.headers.authorization = authUser.refresh_token_header;
    req.data = serializeToFormUrlEncoded({ refresh_token: authUser.refresh_token });
  }

  return req;
};

const responseSuccessInterceptor = (store, res) => {
  if (res?.config?.url === logoutApi) {
    store.dispatch(clearAuthentication());
  }

  if (res?.config?.url === refreshTokenApi) {
    store.dispatch(
      saveAuthentication({ ...res?.data, ...res?.data?.data, ...res?.data?.data?.result })
    );
  }

  return Promise.resolve(res);
};

const responseErrorInterceptor = (store, res) => {
  if (res?.config?.url === refreshTokenApi) {
    store.dispatch(sessionExpired());
  }

  showErrorNotification(res);

  return Promise.reject(res);
};

export { requestSuccessInterceptor, responseErrorInterceptor, responseSuccessInterceptor };
