// Non Auth APIs
const loginApi = '/authentication/login';
const resetPasswordApi = '/cmsUser/resetPassword';
const forgotPassApi = '/cmsUser/sendResetOtp';
const checkOtpApi = '/api/checkOTP';
const twoFAApi = '/api/login2FA';
const resendOtpApi = '/api/resendOTP';
const forceChangePasswordApi = '/api/cmsUser/password/change';

// Auth APIs
const refreshTokenApi = 'authentication/refreshToken';

// PROFILE
const profileApi = '/profile';
const profileDataApi = '/cmsUser/profile';
const logoutApi = '/authentication/logout';
const changePasswordApi = '/cmsUser/password/change';

// LOVs
const getLovListApi = '/profile';

// ACCESS
const userAccessApi = '/sections/permissions';

// ROLES
const getRolesListApi = '/cmsProfile/list';
const rolesPermissionsApi = '/resources';
const getRolesPermissionsByIdApi = '/profile/id';
const addUpdateUserProfileApi = '/profiles';

// SECTIONS
const getCmsSectionsApi = '/sections/allSections';
const cmsSectionApi = '/sections';

const uploadMediaApi = '/media';

export {
  addUpdateUserProfileApi,
  changePasswordApi,
  checkOtpApi,
  cmsSectionApi,
  forceChangePasswordApi,
  forgotPassApi,
  getCmsSectionsApi,
  getLovListApi,
  getRolesListApi,
  getRolesPermissionsByIdApi,
  loginApi,
  logoutApi,
  profileApi,
  profileDataApi,
  refreshTokenApi,
  resendOtpApi,
  resetPasswordApi,
  rolesPermissionsApi,
  twoFAApi,
  uploadMediaApi,
  userAccessApi
};
