const SAVE_AUTHENTICATION = 'SAVE_AUTHENTICATION';
const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const RESET_REFRESHED_TOKEN = 'RESET_REFRESHED_TOKEN';
const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'LOGOUT_FAIL';
const SESSION_EXPIRED = 'SESSION_EXPIRED';
const ONE_SIGNAL_PLAYER_REGISTER = 'ONE_SIGNAL_PLAYER_REGISTER';
const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';

export {
  CLEAR_AUTHENTICATION,
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  ONE_SIGNAL_PLAYER_REGISTER,
  REFRESH_TOKEN,
  RESET_REFRESHED_TOKEN,
  SAVE_AUTHENTICATION,
  SESSION_EXPIRED,
  SET_DEVICE_TOKEN
};
