import moment from 'moment';
import { SET_SETTINGS, ERROR_SETTINGS } from 'configStore/constants/userInfoConstants';
import { CLEAR_AUTHENTICATION, SESSION_EXPIRED } from '../constants/authUserConstants';

export const initialState = {
  settings: null,
  loading: true,
  error: false,
  loadedAt: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SETTINGS:
      return { ...state, settings: payload, loading: false, loadedAt: moment() };
    case ERROR_SETTINGS: {
      if (payload.response?.status !== 401) {
        return { ...state, error: true, loading: false, loadedAt: null };
      }
      return state;
    }
    case CLEAR_AUTHENTICATION:
    case SESSION_EXPIRED:
      return initialState;
    default:
      return state;
  }
};
