import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CircleSpinner from 'components/icons/CircleSpinner';
import logo from 'assets/images/logo.png';
import styles from './PageLoader.module.scss';

const PageLoader = ({ title }) => {
  const { t } = useTranslation();
  const style = {
    height: title === 'loadingCms' ? '100vh' : '100%'
  };

  return (
    <div className={styles.navigationLoader} style={style}>
      <div>
        {title === 'loadingCms' ? (
          <div className="d-block w-100 text-center mb-4">
            <img src={logo} alt="logo" className="no-rotate" />
          </div>
        ) : null}
        <CircleSpinner />
        <h6>{t(title)}</h6>
      </div>
    </div>
  );
};

PageLoader.defaultProps = {
  title: 'loadingCms'
};

PageLoader.propTypes = {
  title: PropTypes.string
};

export default React.memo(PageLoader);
