import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import useScrollToTop from 'hooks/useScrollToTop';
import useGetLanguage from 'hooks/useGetLanguage';
import cmsConfig from 'utils/cmsConfig';
import PageLoader from 'components/atoms/pageLoader/PageLoader';
import Routes from 'routes/index';
import ErrorBoundary from 'components/atoms/errorBoundary/ErrorBoundary';
import ar from 'date-fns/locale/ar-SA';
import en from 'date-fns/locale/en-US';
import history from 'utils/history';
import { store, persistor } from './configStore/index';
import './App.scss';

registerLocale('ar', ar);
registerLocale('en', en);

function App() {
  setDefaultLocale(useGetLanguage());

  useScrollToTop();

  const language = useGetLanguage();
  useEffect(() => {
    if (!cmsConfig.fontSizes) {
      return;
    }

    document.documentElement.style.fontSize = `${
      cmsConfig.fontSizes[language] || cmsConfig.fallbackFontSize || 16
    }px`;
  }, [language]);

  return (
    <>
      <ReactNotifications />
      <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
          <Router history={history}>
            <ErrorBoundary showReloadPage>
              <Routes />
            </ErrorBoundary>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
