import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OneSignal from 'react-onesignal';

import { clearAuthentication } from 'configStore/actions/authUserActions';
import { logout as logoutUser } from 'pages/profile/Profile.service';
import usePromise from './usePromise';

const useAuthenitcation = () => {
  const dispatch = useDispatch();
  const { response, isLoading, fireRequest, error } = usePromise(logoutUser);

  const { isAuthenticated } = useSelector((state) => state.authUser);

  const logout = useCallback(() => {
    fireRequest();
  }, [fireRequest]);

  useEffect(() => {
    if (!response) {
      return;
    }
    OneSignal.setSubscription(false);
    dispatch(clearAuthentication());
  }, [dispatch, response]);

  return {
    isAuthenticated,
    logout,
    isLoading,
    error,
    response
  };
};

export default useAuthenitcation;
