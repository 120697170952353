import { useCallback, useEffect, useRef } from 'react';
import useMountedState from './useMountedState';

export default (cb, saveResponseCb) => {
  if (typeof cb?.then === 'function') {
    throw Error('callback must be a promise.');
  }
  const [isLoading, setIsLoading] = useMountedState(false);
  const [response, setResponse] = useMountedState();
  const [error, setError] = useMountedState(false);
  const resolved = useRef(false);

  useEffect(() => {
    if (error || response) {
      resolved.current = true;
    }
  }, [error, response]);

  return {
    isLoading,
    firedRequest: resolved.current,
    error,
    response,
    setResponse,
    setError,
    fireRequest: useCallback(
      async (useLoading = true, ...params) => {
        if (useLoading) {
          setIsLoading(() => true);
        }
        try {
          const data = await cb(...params);
          if (saveResponseCb) {
            setResponse((prevState) => saveResponseCb(prevState, data));
          } else {
            setResponse(() => data);
          }
        } catch (e) {
          setResponse();
          setError(true);
        } finally {
          setIsLoading(() => false);
        }
      },
      [cb, setIsLoading, setResponse, setError, saveResponseCb]
    )
  };
};
