import axios from 'axios';
import config from 'utils/config';
import { createRefreshToken } from 'middlewares/refreshToken';
import {
  requestSuccessInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptor
} from 'middlewares/interceptors';
import { store } from '../configStore/index';

const instance = axios.create({
  baseURL: config.appUrl,
  timeout: config.appTimeout
});

createRefreshToken(instance);

instance.interceptors.request.use((request) => requestSuccessInterceptor(store, request));
instance.interceptors.response.use(
  (response) => responseSuccessInterceptor(store, response),
  (error) => responseErrorInterceptor(store, error)
);

export default instance;
