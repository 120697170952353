import { useTranslation } from 'react-i18next';
import { formatLanguage } from 'utils/helpers';

const useGetLanguage = () => {
  const {
    i18n: { language }
  } = useTranslation();

  return formatLanguage(language);
};

export default useGetLanguage;
