import moment from 'moment';
import config from 'utils/config';

const negativeNumber = /^-\d*$/;
const negativeNumberAndComma = /^-\d*(\.)?(\d+)?$/;
const onlyNumbersWithoutZero = /^[1-9\b]+$/;
const onlyNumbers = /^[0-9\b]+$/;
const onlyNumbersAndComma = /^\d*(\.)?(\d+)?$/;
const anyLetter = /[a-z]/i;
const allNumbers = /^-?\d*(\.)?(\d+)?$/;

const regexLengthRestrictions = (minL, maxL) => {
  let regex = '';
  if (!minL && maxL) {
    regex = `^.{0,${maxL}}$`;
  }
  if (!maxL && minL) {
    regex = `^.{${minL},}$`;
  }
  if (minL && maxL) {
    regex = `^.{${minL}, ${maxL}}$`;
  }
  return new RegExp(regex, 'gi');
};

const createRegexFromJson = (regex, flag = 'g') => {
  const parsedRegex = JSON.parse(regex).slice(1, -1);
  // slice(1,-1) to remove the quotation marks
  return new RegExp(parsedRegex, flag);
};

const getElapsedTime = (date) => {
  let measurement = 'days';
  let difference = moment().diff(moment(date), measurement);

  if (difference <= 0) {
    measurement = 'hours';
    difference = moment().diff(moment(date), measurement);
  }

  return `${difference} ${measurement}`;
};

const serializeToFormUrlEncoded = (params) =>
  Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

const stopPropagationClick = (e) => e.stopPropagation();

const formatParams = (params, removeEmptyString = false) => {
  const newParams = { ...params };

  Object.keys(newParams).forEach((el) => {
    const value = newParams[el];

    if (value === null || value === undefined) {
      delete newParams[el];
    }

    if (removeEmptyString && typeof value === 'string' && value?.trim() === '') {
      delete newParams[el];
    }

    if (value?.value) {
      newParams[el] = value?.value;
    }
  });

  return newParams;
};

const numberWithCommas = (value) => {
  try {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch {
    return value;
  }
};

const getAssetUrl = (imgName) => config.assetsUrl + imgName;

const findSectionBySectionName = (sections, sectionName) => {
  if (!sections || !sections.length || !sectionName) {
    return null;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const section of sections) {
    if (section?.children?.length) {
      const foundInChildren = findSectionBySectionName(section?.children, sectionName);
      if (foundInChildren) {
        return foundInChildren;
      }
    }

    if (section?.section_name === sectionName) {
      return section;
    }
  }

  return null;
};

const formatUrlParams = (params) => {
  const newParams = { ...params };
  const firstParam = Object.keys(params)[0];

  const urlParam = newParams[firstParam];

  delete newParams[firstParam];

  const queryParam = { ...newParams };

  return { urlParam, queryParam };
};

export {
  allNumbers,
  anyLetter,
  createRegexFromJson,
  findSectionBySectionName,
  formatParams,
  formatUrlParams,
  getAssetUrl,
  getElapsedTime,
  negativeNumber,
  negativeNumberAndComma,
  numberWithCommas,
  onlyNumbers,
  onlyNumbersAndComma,
  onlyNumbersWithoutZero,
  regexLengthRestrictions,
  serializeToFormUrlEncoded,
  stopPropagationClick
};
