import React from 'react';
import ReactDOM from 'react-dom';
import 'react-notifications-component/dist/theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/styles/animation.css';
import i18n from './i18n';
import App from './App';
import './index.scss';

// import reportWebVitals from './reportWebVitals';

i18n.on('initialized', () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
