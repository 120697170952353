const local = 'LOCAL';
const dev = 'DEV';

const config = {
  env: process.env.REACT_APP_ENV,
  environments: {
    local,
    dev,
    uat: 'UAT',
    prod: 'PROD'
  },
  firebase: {
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    vapidKey: process.env.REACT_APP_VAPID_KEY,
    appAPIKey: process.env.REACT_APP_API_KEY,
    appMessagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    firebaseAppId: process.env.REACT_APP_ID
  },
  oneSignalAppId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
  isLocalEnv: process.env.REACT_APP_ENV === local,
  isDevEnv: process.env.REACT_APP_ENV === dev,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  appUrl: process.env.REACT_APP_URL,
  assetsUrl: process.env.REACT_APP_ASSETS_URL,
  appTimeout: process.env.REACT_APP_TIMEOUT,
  websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  imageBaseUrlV1: process.env.REACT_APP_IMAGE_BASE_URL_V1
};

export default config;
