import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import PageLoader from 'components/atoms/pageLoader/PageLoader';
import ErrorBoundary from 'components/atoms/errorBoundary/ErrorBoundary';

import routeNames from 'utils/routeNames';
import useDirection from 'hooks/useDirection';
import ProtectedRoute from './protectedRoute/ProtectedRoute';

const DashboardRouters = lazy(() => import('./DashboardRouters'));
const Login = lazy(() => import('pages/login/Login'));
const ResetPassword = lazy(() => import('pages/resetPassword/ResetPassword'));

const Routes = () => {
  const { dir } = useDirection();
  document.body.dir = dir;

  const { isAuthenticated } = useAuthentication();

  return (
    <Suspense fallback={<PageLoader title="loadingAssets" />}>
      <Switch>
        {!isAuthenticated ? (
          <ErrorBoundary showReloadPage>
            <Switch>
              <Route path={routeNames.login} component={Login} />
              <Route path={routeNames.resetPassword} exact component={ResetPassword} />

              <Redirect to={routeNames.login} />
            </Switch>
          </ErrorBoundary>
        ) : (
          <ProtectedRoute path={routeNames.main} component={DashboardRouters} />
        )}
      </Switch>
    </Suspense>
  );
};
export default Routes;
