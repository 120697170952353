import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import { persistReducer } from 'redux-persist';
import AuthReducer from './authUserReducer';
import UserInfoReducer, { initialState as userInfoInitialState } from './userInfoReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import ProfileReducer from './profileReducer';
import CmsDataReducer from './cmsDataReducer';
import LovReducer, { initialState as lovInitialState } from './lovReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authUser', 'userInfo', 'lov', 'cmsData'],
  transforms: [
    expireReducer('userInfo', {
      persistedAtKey: 'loadedAt',
      expireSeconds: 3600 * 2,
      expiredState: userInfoInitialState
    }),
    expireReducer('lov', {
      persistedAtKey: 'loadedAt',
      expireSeconds: 3600 * 6,
      expiredState: lovInitialState
    })
  ]
};

const reducers = combineReducers({
  authUser: AuthReducer,
  userInfo: UserInfoReducer,
  resetPassword: ResetPasswordReducer,
  profile: ProfileReducer,
  cmsData: CmsDataReducer,
  lov: LovReducer
});

const appReducer = persistReducer(persistConfig, reducers);

export default appReducer;
