import { RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from '../constants/resetPasswordConstants';

const initialState = {
  resetPasswordSuccess: false,
  resetPasswordError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_PASSWORD_ERROR:
      return { ...initialState, resetPasswordError: payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...initialState, resetPasswordSuccess: payload };
    default:
      return state;
  }
};
