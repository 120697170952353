import {
  CLEAR_AUTHENTICATION,
  SAVE_AUTHENTICATION,
  REFRESH_TOKEN,
  RESET_REFRESHED_TOKEN,
  SESSION_EXPIRED,
  ONE_SIGNAL_PLAYER_REGISTER,
  SET_DEVICE_TOKEN
} from '../constants/authUserConstants';

const initialState = {
  access_token: undefined,
  refresh_token: undefined,
  refresh_token_header: undefined,
  isAuthenticated: false,
  refreshedToken: false,
  sessionExpire: false,
  isOneSignalRegistered: false,
  logoutLoading: false,
  logoutError: null,
  logoutResult: null,
  deviceToken: null
};

const authUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_AUTHENTICATION:
      return {
        ...state,
        sessionExpire: false,
        isAuthenticated: true,
        ...payload
      };
    case CLEAR_AUTHENTICATION: {
      return initialState;
    }
    case REFRESH_TOKEN:
      return { ...state, ...payload, refreshedToken: true };
    case RESET_REFRESHED_TOKEN:
      return {
        ...state,
        refreshedToken: false
      };
    case ONE_SIGNAL_PLAYER_REGISTER: {
      return { ...state, isOneSignalRegistered: true };
    }
    case SET_DEVICE_TOKEN: {
      return {
        ...state,
        deviceToken: payload
      };
    }
    case SESSION_EXPIRED:
      return { ...initialState, sessionExpire: true };

    default:
      return state;
  }
};

export default authUserReducer;
