const routeNames = {
  main: '/',
  notFound: '/404',
  login: '/login',
  resetPassword: '/reset/:token',
  profile: '/account',
  permissions: {
    baseUrl: '/permissions',
    add: '/permissions/new',
    edit: (id) => ({
      href: '/permissions/edit/:id',
      as: `/permissions/edit/${id}`
    })
  },
  addVideo: '/videos/video/new',
  chartsDetail: (id) => ({ href: '/charts/detail/:id', as: `/charts/detail/${id}` })
};

export default routeNames;
