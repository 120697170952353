import { GET_PROFILE, GET_PROFILE_ERROR, GET_PROFILE_LOADING } from '../constants/profileConstants';

const initialState = {
  loading: false,
  profile: null,
  error: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_LOADING:
      return { ...state, loading: !state.profile };
    case GET_PROFILE:
      return { ...state, profile: payload, loading: false };
    case GET_PROFILE_ERROR:
      return { ...state, loading: false, error: false, profile: null };
    default:
      return state;
  }
};
