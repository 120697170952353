import i18next from 'i18next';
import { Store } from 'react-notifications-component';
import React from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import CustomAlert from 'components/atoms/customAlert/CustomAlert';
import placeholder from 'assets/images/placeholder.png';
import merge from 'lodash/merge';

const getArrayTruthyValues = (array) =>
  Object.keys(array).reduce((prev, current) => (array[current] ? [...prev, current] : prev), []);

const isRtl = () => i18next.dir() === 'rtl';

const formatLanguage = (language) => {
  try {
    return language.split('-')[0].toLowerCase();
  } catch {
    return language;
  }
};

const language = () => formatLanguage(i18next.language);

const notificationConfig = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    showIcon: false
  }
};

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      const base64 = dataUrl.split(',')[1];
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });

const onImageError = (e) => {
  e.target.src = placeholder;
};

const showNotification = (message, type = 'success', title) => {
  Store.addNotification({
    title,
    message: <CustomAlert message={message} type={type} />,
    type,
    ...notificationConfig
  });
};

const extractErrorMessage = (response) => {
  let errorMessage;
  try {
    if (typeof response?.data?.data?.message === 'object') {
      errorMessage = response.data?.data?.message[language().split('-')[0]];
    } else if (Array.isArray(response.data)) {
      const { data } = response;
      const [errorObject] = data;

      [errorMessage] = errorObject.messages;
    } else {
      errorMessage = response.data?.data?.message;
    }
  } catch {
    showNotification('somethingWentWrong', 'danger');
  }
  return errorMessage ?? null;
};

const showErrorNotification = debounce((error) => {
  let message;

  if (error.response) {
    const { response } = error;
    if (response.request.responseURL === 'https://wizzo-api.eurisko.me/cms/ads/publish') {
      showNotification('Another Ad is already published', 'danger');
      return;
    }
    if (response.status !== 401) {
      switch (response.status) {
        case 400:
          message = extractErrorMessage(response);
          break;
        case 404:
          showNotification('notFound', 'danger');
          break;
        case 413:
          showNotification('forbidden', 'danger');
          break;
        case 500:
          showNotification('somethingWentWrong', 'danger');
          break;
        default: {
          message = extractErrorMessage(response);
        }
      }
    }
  } else if (error.code === 'ECONNABORTED') {
    message = 'Error Timeout';
  } else {
    message = 'Connection Error';
  }
  if (message) {
    Store.addNotification({
      message,
      type: 'danger',
      ...notificationConfig
    });
  }
}, 500);

const exportDownload = (file, fileName) => {
  const data = new window.Blob([file]);
  if (typeof window.navigator.msSaveBlob === 'function') {
    // If it is IE that support download blob directly.
    window.navigator.msSaveBlob(data, fileName);
  } else {
    const url = window.URL.createObjectURL(new window.Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const isShallowEqual = (v, o) => {
  let equal = true;
  // eslint-disable-next-line consistent-return
  Object.keys(v).forEach((key) => {
    if (!(key in o) || v[key] !== o[key]) {
      equal = false;
      return false;
    }
  });

  // eslint-disable-next-line consistent-return
  Object.keys(o).forEach((key) => {
    if (!(key in v) || v[key] !== o[key]) {
      equal = false;
      return false;
    }
  });

  return equal;
};

const isAllEqual = (arr) => arr.every((v) => isEqual(v, arr[0]));

const countWord = (text) => {
  const pattern =
    /[a-zA-Z0-9_\u0392-\u03c9\u00c0-\u00ff\u0600-\u06ff\u0400-\u04ff]+|[\u4e00-\u9fff\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
  const m = text.match(pattern);

  let count = 0;
  if (!m) {
    return 0;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < m.length; i++) {
    if (m[i].charCodeAt(0) >= 0x4e00) {
      count += m[i].length;
    } else {
      count += 1;
    }
  }
  return count;
};

const getChanges = (right, left) => {
  const fields = {};
  const keys = Object.keys(right);

  keys.forEach((key) => {
    if (right[key] !== left[key]) {
      fields[key] = right[key];
    }
  });

  return fields;
};

const ellipsisString = (text, words) => {
  const str = text.split(' ');

  return str.length > words ? `${str.slice(0, 10).join(' ')}...` : text;
};

const trimString = (string, limit) => {
  try {
    return string.length < limit ? string : `${string.substring(0, limit)}...`;
  } catch {
    return string;
  }
};

const removeFirstOccurrence = (str, searchstr) => {
  const index = str.indexOf(searchstr);
  if (index === -1) {
    return str;
  }

  return str.slice(0, index) + str.slice(index + searchstr.length);
};

const getDuration = (time) => {
  if (time <= 0) {
    return { minutes: 0, seconds: 0 };
  }

  const minutes = Math.floor(time / 60);
  const seconds = (time - minutes * 60).toFixed(0);
  return { minutes, seconds };
};

const noSpace = (value) => value.trim() !== '' || value === '';

const formatOption = (option) => ({
  ...option,
  label: option.text || option.name,
  value: option._id || option.value || option.id
});

const formatResponseOptions = ({ data: { result } }) => result?.map(formatOption);

const mergeRoutes = (a, b) => {
  const initialObj = { ...a };
  const secondObj = { ...b };
  const resultObj = {};

  try {
    Object.keys(initialObj).forEach((key) => {
      if (key in secondObj) {
        resultObj[key] = [...initialObj[key], ...secondObj[key]];
        delete secondObj[key];
      } else {
        resultObj[key] = [...initialObj[key]];
      }
    });

    Object.keys(secondObj).forEach((key) => {
      resultObj[key] = [...secondObj[key]];
    });

    return resultObj;
  } catch {
    return resultObj;
  }
};

const prependLink = (link) => (link?.startsWith('/') ? link : `/${link}`);

const getDateValue = (date) => (date ? new Date(date) : null);

const getUserTimezoneOffset = () => new Date().getTimezoneOffset() / 60;

// eslint-disable-next-line no-console
const devLog = (...args) => process.env.NODE_ENV === 'development' && console.log(...args);

const getTimeInSeconds = (value) => {
  try {
    const array = value?.split(':');
    if (!value || !array.length) {
      return value;
    }

    array.reverse();
    let returnValue = 0;

    // eslint-disable-next-line no-plusplus
    for (let index = array.length - 1; index >= 0; index--) {
      returnValue += +array[index] * 60 ** index;
    }

    return returnValue;
  } catch {
    return value;
  }
};

const getTrackDuration = (value) => {
  const { minutes, seconds } = getDuration(value);

  return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
};

const getDropDownOptions = (type) => {
  switch (type) {
    case 'text':
      return [
        {
          label: 'Contains',
          value: 1
        },
        {
          label: 'Starts With',
          value: 2
        },
        {
          label: 'Ends With',
          value: 3
        }
      ];
    case 'number':
      return [
        {
          label: 'Less Than',
          value: 1
        },
        {
          label: 'Greater Than',
          value: 2
        },
        {
          label: 'Equal To',
          value: 3
        },
        {
          label: 'Different Than',
          value: 4
        }
      ];
    default:
      return [];
  }
};

const isEqualArrayElements = (arrayOne, arrayTwo) => {
  if (arrayOne.length !== arrayTwo.length) {
    return false;
  }

  let equalArrays = true;
  const allValues = [...arrayOne, ...arrayTwo];

  // eslint-disable-next-line consistent-return
  allValues.forEach((el) => {
    const arrayOneCount = arrayOne.filter((e) => e === el).length;
    const arrayTwoCount = arrayTwo.filter((e) => e === el).length;

    if (arrayOneCount !== arrayTwoCount) {
      equalArrays = false;
      return false;
    }
  });

  return equalArrays;
};

const mergeOrOverride = (target, object, rule) => {
  if (rule === 'override') {
    return { ...object };
  }

  return merge({ ...target }, { ...object });
};

export {
  blobToBase64,
  countWord,
  devLog,
  ellipsisString,
  exportDownload,
  formatLanguage,
  formatOption,
  formatResponseOptions,
  getArrayTruthyValues,
  getChanges,
  getDateValue,
  getDropDownOptions,
  getDuration,
  getTimeInSeconds,
  getTrackDuration,
  getUserTimezoneOffset,
  isAllEqual,
  isEqualArrayElements,
  isRtl,
  isShallowEqual,
  language,
  mergeOrOverride,
  mergeRoutes,
  noSpace,
  notificationConfig,
  onImageError,
  prependLink,
  removeFirstOccurrence,
  showErrorNotification,
  showNotification,
  trimString
};
