import {
  SAVE_AUTHENTICATION,
  CLEAR_AUTHENTICATION,
  REFRESH_TOKEN,
  RESET_REFRESHED_TOKEN,
  SESSION_EXPIRED,
  SET_DEVICE_TOKEN
} from '../constants/authUserConstants';

const refreshUserToken = (payload) => ({
  type: REFRESH_TOKEN,
  payload
});

const restRefreshedToken = () => ({
  type: RESET_REFRESHED_TOKEN
});

const sessionExpired = () => ({
  type: SESSION_EXPIRED
});

const saveAuthentication = (payload) => ({
  type: SAVE_AUTHENTICATION,
  payload
});

const clearAuthentication = () => ({
  type: CLEAR_AUTHENTICATION,
  payload: null
});

const setDeviceToken = (token) => ({
  type: SET_DEVICE_TOKEN,
  payload: {
    token
  }
});

export {
  clearAuthentication,
  refreshUserToken,
  restRefreshedToken,
  saveAuthentication,
  sessionExpired,
  setDeviceToken
};
