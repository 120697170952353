import LocalStorageBackend from 'i18next-localstorage-backend';
import XHRBackEnd from 'i18next-xhr-backend';
import config from 'utils/config';

const { isDevEnv } = config;

export const backEnds = isDevEnv ? [XHRBackEnd] : [LocalStorageBackend, XHRBackEnd];

export const loadPath = () => '/locales/{{lng}}/{{ns}}.json';

export const backEndOptions = isDevEnv
  ? [{ loadPath }]
  : [{ prefix: 'app_name_', versions: { en: 'v1', ar: 'v1' } }, { loadPath }];
