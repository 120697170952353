import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import routeNames from 'utils/routeNames';

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuthentication();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          // eslint-disable-next-line react/prop-types
          <Redirect to={{ pathname: routeNames.login, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
